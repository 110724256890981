import {
  AccountMeta,
  Connection,
  LAMPORTS_PER_SOL,
  MemcmpFilter,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
  Keypair
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token,
  AccountLayout
} from "@solana/spl-token";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
import * as bs58 from "bs58";
import { serialize, deserialize, deserializeUnchecked, } from 'borsh';
/* global BigInt */


// let admin = "ArURmpNyeTvJyS2VJHjQfbAw1itCiJVEEctVJNsn8WgK".parse::<Pubkey>().unwrap();
//     let fees_vault = "BPoRALdcEpPtFKLHuWurYXf656EoeJRfWFqQQnCtszXX".parse::<Pubkey>().unwrap();
//     let entropy_price = "HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J".parse::<Pubkey>().unwrap();




// export const PROGRAM_ID = new PublicKey(
//   "9ryqBExwmxu3PmPSRLxgUUAjLN3sCgjgmH5fHjppvkHJ"
// );
// const ENTROPY_PRICE = new PublicKey(
//   "HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J"
// );

//  const FEES_VAULT = new PublicKey(
//   "AgiQRZnoTHLc8SZjRRz47M6CQkMMjf1huPinrphSSqSD"
// )

export const PROGRAM_ID = new PublicKey(
  "BgLzyY2i2MSzuW7nWgeoPB5tjRHudMJxmHKkKZ3E4GEy"
);
const ENTROPY_PRICE = new PublicKey(
  "GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU"
);

 const FEES_VAULT = new PublicKey(
  "BPoRALdcEpPtFKLHuWurYXf656EoeJRfWFqQQnCtszXX"
)


// export const PROGRAM_ID = new PublicKey(
//   "14MxKaiLzKWCdhJZiLZ3pXaEj2fpRY4bGU2X1pUBKbSX"
// );
// const ENTROPY_PRICE = new PublicKey(
//   "HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J"
// );

//  const FEES_VAULT = new PublicKey(
//   "Ac3aQfdvQyUQzNwPCYNdrAfGRZJNdZgucmeweangvAtU"
// )



const FEES_VAULT_TOKEN = new PublicKey(
  "AqmpPpsjnM7KvtVdAYtC6nNKGGMZaUtXDEY32Z7AGw9a"
)

class Assignable {
  constructor(properties) {
    Object.keys(properties).map((key) => {
      return (this[key] = properties[key]);
    });
  }
}
class Payload extends Assignable {}


function createInstructionData(instruction: string, raffle_program_word:String, winner_count, prize_amount_per_winner, end_timestamp, max_entries, ticket_price, max_tickets_per_wallet, is_dao, has_escrow, winner_list, ticket_amount): any {

  const RafflePayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["winner_count", "u64"],
          ["raffle_program_word", "string"],
          ["prize_amount_per_winner", "u64"],
          ["end_timestamp", "u64"],
          ["max_entries", "u64"],
          ["ticket_price", "u64"],
          ["max_tickets_per_wallet", "u64"],
          ["is_dao", "u8"],
          ["has_escrow", "u8"],
        ],
      },
    ],
  ]);

  const BuyTicketdPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["raffle_program_word", "string"],
          ["ticket_amount", "u64"],
        ],
      },
    ],
  ]);

  const RevealWLWinnersPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["raffle_program_word", "string"],
          ["winner_list", ["u64"]],
        ],
      },
    ],
  ]);

  const RaffleWordPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["raffle_program_word", "string"],
        ],
      },
    ],
  ]);
  const WLPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
        ],
      },
    ],
  ]);

  if (instruction === "RaffleInit"){
    const Data = new Payload({
      id:0,
      winner_count,
      raffle_program_word,
      prize_amount_per_winner,
      end_timestamp,
      max_entries,
      ticket_price,
      max_tickets_per_wallet,
      is_dao,
      has_escrow
      });
    return Buffer.from(serialize(RafflePayloadSchema, Data));
  }
  else if (instruction === "BuyTickets"){
    const Data = new Payload({
      id:1,
      raffle_program_word,
      ticket_amount,
      });
    return Buffer.from(serialize(BuyTicketdPayloadSchema, Data));
  }
  else if (instruction === "RevealWinner"){
    const Data = new Payload({
      id:2,
      raffle_program_word,
      });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  }
  else if (instruction === "RevealWLWinners"){
    const Data = new Payload({
      id:3,
      raffle_program_word,
      winner_list,
      });
    return Buffer.from(serialize(RevealWLWinnersPayloadSchema, Data));
  }
  else if (instruction === "ClaimPrize"){
    const Data = new Payload({
      id:4,
      raffle_program_word,
      });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  }
  else if (instruction === "ClaimWL"){
    const Data = new Payload({
      id:7,
      raffle_program_word,
      });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  }
  else if (instruction === "CancelRaffle"){
    const Data = new Payload({
      id:5,
      raffle_program_word,
      });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  }
  else if (instruction === "AddToWL"){
    const Data = new Payload({
      id:6,
      });
    return Buffer.from(serialize(WLPayloadSchema, Data));
  }
  throw new Error(`Unrecognized instruction: ${instruction}`);
}

function parseUint64Le(data: Uint8Array, offset: number = 0): bigint {
  let number = BigInt(0);
  for (let i = 0; i < 8; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 8);
  return number;
}

function getAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenAddress: PublicKey,
  allowOffCurve: boolean = false
): Promise<PublicKey> {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenAddress,
    walletAddress,
    allowOffCurve
  );
}

function transactionKey(
  pubkey: PublicKey,
  isSigner: boolean,
  isWritable: boolean = true
): AccountMeta {
  return {
    pubkey,
    isSigner,
    isWritable,
  };
}

export async function getRaffleDataAddress(
  programWord: String
): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [Buffer.from(programWord)],
    PROGRAM_ID
  );
  return address;
}

// export async function RaffleData(
//   connection: Connection,
//   raffleWord: String
// ): Promise<any> {
//   let raffleDataAddress = await getRaffleDataAddress(raffleWord);
//   let raffleAccountInfo = await connection.getAccountInfo(raffleDataAddress);
//   // if (!raffleAccountInfo) throw new Error(`${raffleDataAddress} not initialized`);
//    if (!raffleAccountInfo) return
//   let { data } = raffleAccountInfo;
//   // console.log(data);
//   let tempData = {
//     timestamp: Number(parseUint64Le(data, 0)),
//     creator: (new PublicKey(data.slice(8, 40))).toBase58(),
//     prize_key:( new PublicKey(data.slice(40, 72))).toBase58(),
//     active: new TextDecoder("utf-8").decode(data.slice(72,73)),
//     hasEscrow: new TextDecoder("utf-8").decode(data.slice(73,74)),
//     end_timestamp: Number(parseUint64Le(data, 74)),
//     winner_count: Number(parseUint64Le(data, 82)),
//     max_entries: Number(parseUint64Le(data, 90)),
//     total_entries: Number(parseUint64Le(data, 98)),
//     ticket_price: Number(parseUint64Le(data, 106)),
//     prize_amount_per_winner: Number(parseUint64Le(data, 114)),
//     max_tickets_per_wallet: Number(parseUint64Le(data, 122)),
//     ticket_mint:( new PublicKey(data.slice(130, 162))).toBase58(),
//     account_pubkey: ( new PublicKey(data.slice(162, 194))).toBase58(),
//     entries: [],
//     winners: [],
//   };

//   let arrayAccountInfo = await connection.getAccountInfo(new PublicKey(data.slice(162, 194)));
//   if (!arrayAccountInfo) throw new Error(`Account not initialized`);
//   {
//     let {data} = arrayAccountInfo;

//     let next;
//     for(let i=0; i<tempData.total_entries; i++){
//       tempData.entries.push(( new PublicKey(data.slice(68+(i*32), 68+32+(i*32)))).toBase58())
//       next = 68+32+(i*32);
//     }
//     let next2;
//     if(tempData.active == "\u0000"){
//       for(let i=0; i<Math.min(tempData.winner_count, tempData.total_entries); i++){
//         tempData.winners.push(( new PublicKey(data.slice(next+4+(i*32), next+4+32+(i*32)))).toBase58());
//         next2 = next+4+32+(i*32);
//       }
//     }
//   }

//   return tempData;
// }

export async function RaffleData(
  connection: Connection,
  raffleWord: String
): Promise<any> {
  let raffleDataAddress = await getRaffleDataAddress(raffleWord);
  let raffleAccountInfo = await connection.getAccountInfo(raffleDataAddress);
  // if (!raffleAccountInfo) throw new Error('${raffleDataAddress} not initialized');
  if (!raffleAccountInfo) return
  let { data } = raffleAccountInfo;
  // console.log(data);
  let tempData = {
    timestamp: Number(parseUint64Le(data, 0)),
    creator: (new PublicKey(data.slice(8, 40))).toBase58(),
    prize_key:( new PublicKey(data.slice(40, 72))).toBase58(),
    active: new TextDecoder("utf-8").decode(data.slice(72,73)),
    hasEscrow: new TextDecoder("utf-8").decode(data.slice(73,74)),
    end_timestamp: Number(parseUint64Le(data, 74)),
    winner_count: Number(parseUint64Le(data, 82)),
    max_entries: Number(parseUint64Le(data, 90)),
    total_entries: Number(parseUint64Le(data, 98)),
    ticket_price: Number(parseUint64Le(data, 106)),
    prize_amount_per_winner: Number(parseUint64Le(data, 114)),
    max_tickets_per_wallet: Number(parseUint64Le(data, 122)),
    ticket_mint:( new PublicKey(data.slice(130, 162))).toBase58(),
    account_pubkey: ( new PublicKey(data.slice(162, 194))).toBase58(),
    entries: [],
    winners: [],
  };
  // console.log("test", ( new PublicKey(data.slice(298,330))).toBase58())
  let arrayAccountInfo = await connection.getAccountInfo(new PublicKey(data.slice(162, 194)));
  if (!arrayAccountInfo) throw new Error('Account not initialized');
  {
    let {data} = arrayAccountInfo;
    // console.log()
    let next;
    for(let i=0; i<tempData.total_entries; i++){
      tempData.entries.push(( new PublicKey(data.slice(68+(i*32), 68+32+(i*32)))).toBase58())
      next = 68+32+(i*32);
    }
    let next2;
    if(tempData.active == "\u0000"){
      for(let i=0; i<Math.min(tempData.winner_count, tempData.total_entries); i++){
        tempData.winners.push(( new PublicKey(data.slice(next+4+(i*32), next+4+32+(i*32)))).toBase58());
        next2 = next+4+32+(i*32);
      }
    }
  }
  console.log(tempData);
  return tempData;
}

const WHITELIST_PREFIX = "whitelist";
export async function getWhitelistDataAddress(
  token: PublicKey
): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [Buffer.from(WHITELIST_PREFIX), token.toBytes()],
    PROGRAM_ID
  );
  return address;
}

export async function getAllTokens(
  connection: Connection,
  wallet: PublicKey
): Promise<any> {
  const tokenAccounts = await connection.getTokenAccountsByOwner(wallet, {
    programId: TOKEN_PROGRAM_ID,
  });

  let whitelist_array = [];
  for (const e of tokenAccounts.value) {
    const accountInfo = AccountLayout.decode(e.account.data);
    // console.log(accountInfo)
    let addy = await getWhitelistDataAddress(new PublicKey(accountInfo.mint));
    let addyAccountInfo = await connection.getAccountInfo(addy);
    // console.log(`${new PublicKey(accountInfo.mint)}   ${parseUint64Le(accountInfo.amount)}`);
    if (addyAccountInfo) {
      console.log(
        `${new PublicKey(accountInfo.mint)}   ${parseUint64Le(
          accountInfo.amount
        )}`
      );

      let whitelisted_address = new PublicKey(accountInfo.mint);

      let tokenmetaPubkey = await Metadata.getPDA(
        new PublicKey(accountInfo.mint)
      );
      // console.log(tokenmetaPubkey.toString())

      try{
        let sol_res = await fetch("https://public-api.solscan.io/token/meta?tokenAddress="+whitelisted_address)
        let sol_data = await sol_res.json()
        console.log(sol_data)
        let t = {
          address: whitelisted_address.toString(),
          name: sol_data['name'],
          image: sol_data["icon"],
        };
        whitelist_array.push(t);
        // const tokenmeta = await Metadata.load(connection, tokenmetaPubkey);

        // let img_response = await fetch(tokenmeta["data"]["data"]["uri"]);
        // let img = await img_response.json();
        // let t = {
        //   address: whitelisted_address.toString(),
        //   meta_data: tokenmeta.data,
        //   image: img["image"],
        // };
        // whitelist_array.push(t);
      }catch(e){
        console.log(e);
        let t = {
          address: whitelisted_address.toString(),
          name: "",
          image: "",
        };
        whitelist_array.push(t);
      }





    }
    // console.log(JSON.stringify(accountInfo));
  }
  return whitelist_array;
}

export async function getAllNFT(
  connection: Connection,
  wallet: PublicKey
): Promise<any> {
  let whitelist_array = [];
  //NFTs
  const walletNfts = await Metadata.findDataByOwner(connection, wallet);
  await Promise.all(
    walletNfts.map(async ({ mint, data }) => {
      if (data.creators && data.creators[0]?.verified) {
        //NFTs
        // console.log(data)
        let addy = await getWhitelistDataAddress(
          new PublicKey(data.creators[0].address)
        );
        let addyAccountInfo = await connection.getAccountInfo(addy);
        if (addyAccountInfo) {
          // console.log(`${new PublicKey(mint)}`);

          let whitelisted_address = new PublicKey(mint);

          let tokenmetaPubkey = await Metadata.getPDA(new PublicKey(mint));
          // console.log(tokenmetaPubkey.toString());
          const tokenmeta = await Metadata.load(connection, tokenmetaPubkey);
          // console.log(tokenmeta);
          let img_response = await fetch(tokenmeta["data"]["data"]["uri"]);
          let img = await img_response.json();
          let t = {
            address: whitelisted_address.toString(),
            meta_data: tokenmeta.data,
            image: img["image"],
            name: tokenmeta["data"]["data"]["name"],
            cmid:data.creators[0].address
          };
          whitelist_array.push(t);
        }
      }
    })
  );

  return whitelist_array;
}


export async function createAccountTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  winner_count: any,
  max_entries: any,
  dataAddress:PublicKey
  ): Promise<Transaction> {

  let transaction = new Transaction();
  // let raffleAddress = await getRaffleDataAddress(raffleWord);
  let size = 8+32+32+(32*max_entries)+8+(32*winner_count)+8+(32*winner_count);
  let pid = new PublicKey(PROGRAM_ID);
  let rent =await connection.getMinimumBalanceForRentExemption(size);
  const instruction = SystemProgram.createAccount({
      fromPubkey: creator,
      newAccountPubkey: dataAddress,
      space: size,
      lamports: rent,
      programId: pid,
  });
  transaction.add(instruction);
  return transaction;
}

export async function createRaffleInitTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  winner_count: any,
  prize_amount_per_winner: any,
  end_timestamp: any,
  max_entries: any,
  ticket_price: any,
  max_tickets_per_wallet: any,
  isDao: any,
  hasEscrow: any,
  ticket_token: PublicKey,
  prize_token: PublicKey,
  data_account:PublicKey,
  checkWLtoken:PublicKey,
  ): Promise<Transaction> {
  // console.log(prize_amount_per_winner, ticket_price, hasEscrow, isDao, ticket_token.toBase58(), prize_token.toBase58())
  let transaction = new Transaction();

  transaction.add(await createAccountTransaction(connection, creator, raffleWord, winner_count, max_entries, data_account));
  transaction.add(
    await createRaffleInitInstruction(
      creator,
      raffleWord,
      winner_count,
      prize_amount_per_winner,
      end_timestamp,
      max_entries,
      ticket_price,
      max_tickets_per_wallet,
      isDao,
      hasEscrow,
      ticket_token,
      prize_token,
      data_account,
      checkWLtoken
    )
  );
  return transaction;
}
export async function createRaffleInitInstruction(
  creator: PublicKey,
  raffleWord: String,
  winner_count: any,
  prize_amount_per_winner: any,
  end_timestamp:any,
  max_entries: any,
  ticket_price: any,
  max_tickets_per_wallet: any,
  isDao: any,
  hasEscrow: any,
  ticket_token: PublicKey,
  prize_token: PublicKey,
  data_account: PublicKey,
  checkWLtoken:PublicKey,
): Promise<TransactionInstruction> {

    let prizeSourceTokenAccount = await getAssociatedTokenAddress(creator, prize_token);

    let raffleAddress = await getRaffleDataAddress(raffleWord);

    let prizeDestinationTokenAccount = await getAssociatedTokenAddress(raffleAddress, prize_token, true);

    let tokenMintAccount = await getAssociatedTokenAddress(raffleAddress, ticket_token, true);
    let whitelistDataAddress = await getWhitelistDataAddress(ticket_token);
    let whitelistDaoDataAddress = await getWhitelistDataAddress(creator);
    let whitelistMintDataAddress = await getWhitelistDataAddress(checkWLtoken);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("RaffleInit", raffleWord, winner_count, prize_amount_per_winner, end_timestamp, max_entries, ticket_price, max_tickets_per_wallet, isDao, hasEscrow, null, null),
    keys: [
      transactionKey(creator, true),

      transactionKey(prize_token, false, false),
      transactionKey(prizeSourceTokenAccount, false),
      transactionKey(prizeDestinationTokenAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(ticket_token, false, false),
      transactionKey(tokenMintAccount, false),

      transactionKey(whitelistDaoDataAddress, false),
      transactionKey(whitelistMintDataAddress, false),
      transactionKey(checkWLtoken, false, false),
      transactionKey(whitelistDataAddress, false),

      transactionKey(raffleAddress, false),
      transactionKey(data_account, false),
    ],
  });
}

export async function createRevealWinnerTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  ticket_token: PublicKey,
  data_account: PublicKey
  ): Promise<Transaction> {

  let transaction = new Transaction();
  console.log(ticket_token.toString(), data_account.toString())
  transaction.add(
    await createRevealWinnerInstruction(
      creator,
      raffleWord,
      ticket_token,
      data_account
    )
  );
  return transaction;
}
export async function createRevealWinnerInstruction(
  creator: PublicKey,
  raffleWord: String,
  ticket_token: PublicKey,
  data_account: PublicKey
): Promise<TransactionInstruction> {

    let raffleAddress = await getRaffleDataAddress(raffleWord);

    let feeDestAccount = await getAssociatedTokenAddress(FEES_VAULT, ticket_token);

    let tokenMintSrcAccount = await getAssociatedTokenAddress(raffleAddress, ticket_token, true);
    let tokenMintDestAccount = await getAssociatedTokenAddress(creator, ticket_token);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("RevealWinner", raffleWord, null, null, null, null, null, null, null, null, null, null),
    keys: [
      transactionKey(creator, true),
      transactionKey(ENTROPY_PRICE, false),

      transactionKey(ticket_token, false, false),
      transactionKey(tokenMintSrcAccount, false),
      transactionKey(tokenMintDestAccount, false),

      transactionKey(FEES_VAULT, false),
      transactionKey(feeDestAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(data_account, false),
    ],
  });
}


export async function createRevealWLWinnersTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  ticket_token: PublicKey,
  winner_list: any,
  prize_token: PublicKey,
  data_account: PublicKey,
  ): Promise<Transaction> {

  let transaction = new Transaction();
  transaction.add(
    await createRevealWLWinnersInstruction(
      creator,
      raffleWord,
      ticket_token,
      winner_list,
      prize_token,
      data_account
    )
  );
  return transaction;
}
export async function createRevealWLWinnersInstruction(
  creator: PublicKey,
  raffleWord: String,
  ticket_token: PublicKey,
  winner_list:any,
  prize_token: PublicKey,
  account_data: PublicKey,

): Promise<TransactionInstruction> {

    let raffleAddress = await getRaffleDataAddress(raffleWord);

    let feeDestAccount = await getAssociatedTokenAddress(FEES_VAULT, ticket_token);

    let tokenMintSrcAccount = await getAssociatedTokenAddress(raffleAddress, ticket_token, true);
    let tokenMintDestAccount = await getAssociatedTokenAddress(creator, ticket_token);
    let prizeMintSrcAccount = await getAssociatedTokenAddress(raffleAddress, prize_token, true);
    let prizeMintDestAccount = await getAssociatedTokenAddress(creator, prize_token);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("RevealWLWinners", raffleWord, null, null, null, null, null, null, null, null, winner_list, null),
    keys: [
      transactionKey(creator, true),

      transactionKey(ticket_token, false, false),
      transactionKey(tokenMintSrcAccount, false),
      transactionKey(tokenMintDestAccount, false),

      transactionKey(prize_token, false, false),
      transactionKey(prizeMintSrcAccount, false),
      transactionKey(prizeMintDestAccount, false),

      transactionKey(FEES_VAULT, false),
      transactionKey(feeDestAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}



export async function createBuyTicketsTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  ticket_token: PublicKey,
  ticket_amount: any,
  data_account: PublicKey
  ): Promise<Transaction> {

  let transaction = new Transaction();
  // console.log("createBuyTicketsTransaction")
  // console.log("===========================")
  // console.log(raffleWord, ticket_token.toBase58(), ticket_amount, data_account.toBase58())
  transaction.add(
    await createBuyTicketsInstruction(
      creator,
      raffleWord,
      ticket_token,
      ticket_amount,
      data_account
    )
  );
  return transaction;
}
export async function createBuyTicketsInstruction(
  creator: PublicKey,
  raffleWord: String,
  ticket_token: PublicKey,
  ticket_amount: any,
  data_account: PublicKey
): Promise<TransactionInstruction> {

    let raffleAddress = await getRaffleDataAddress(raffleWord);

    let tokenMintSrcAccount = await getAssociatedTokenAddress(creator, ticket_token);
    let tokenMintDestAccount = await getAssociatedTokenAddress(raffleAddress, ticket_token, true);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("BuyTickets", raffleWord, null, null, null, null, null, null, null, null, null,ticket_amount),
    keys: [
      transactionKey(creator, true),

      transactionKey(tokenMintSrcAccount, false),
      transactionKey(tokenMintDestAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(data_account, false),
    ],
  });
}


export async function createClaimPrizeTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey,
  ): Promise<Transaction> {

  let transaction = new Transaction();
  transaction.add(
    await createClaimPrizeInstruction(
      creator,
      raffleWord,
      prize_token,
      account_data
    )
  );
  return transaction;
}
export async function createClaimPrizeInstruction(
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey,
): Promise<TransactionInstruction> {

    let raffleAddress = await getRaffleDataAddress(raffleWord);

    let prizeMintSrcAccount = await getAssociatedTokenAddress(raffleAddress, prize_token, true);
    let prizeMintDestAccount = await getAssociatedTokenAddress(creator, prize_token);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("ClaimPrize", raffleWord, null, null, null, null, null, null, null, null, null,null),
    keys: [
      transactionKey(creator, true),

      transactionKey(prize_token, false, false),
      transactionKey(prizeMintSrcAccount, false),
      transactionKey(prizeMintDestAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}

export async function createClaimWLTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  account_data: PublicKey,
  ): Promise<Transaction> {

  let transaction = new Transaction();
  transaction.add(
    await createClaimWLInstruction(
      creator,
      raffleWord,
      account_data
    )
  );
  return transaction;
}
export async function createClaimWLInstruction(
  creator: PublicKey,
  raffleWord: String,
  account_data: PublicKey,
): Promise<TransactionInstruction> {

    let raffleAddress = await getRaffleDataAddress(raffleWord);

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("ClaimWL", raffleWord, null, null, null, null, null, null, null, null, null,null),
    keys: [
      transactionKey(creator, true),
      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}

export async function createCancelRaffleTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey,
  ): Promise<Transaction> {

  let transaction = new Transaction();
  transaction.add(
    await createCancelRaffleInstruction(
      creator,
      raffleWord,
      prize_token,
      account_data
    )
  );
  return transaction;
}
export async function createCancelRaffleInstruction(
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey,
): Promise<TransactionInstruction> {

    let raffleAddress = await getRaffleDataAddress(raffleWord);

    let prizeMintSrcAccount = await getAssociatedTokenAddress(raffleAddress, prize_token, true);
    let prizeMintDestAccount = await getAssociatedTokenAddress(creator, prize_token);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("CancelRaffle", raffleWord, null, null, null, null, null, null, null, null, null,null),
    keys: [
      transactionKey(creator, true),

      transactionKey(prize_token, false, false),
      transactionKey(prizeMintSrcAccount, false),
      transactionKey(prizeMintDestAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}


export async function createAddToWLTransaction(
  connection: Connection,
  creator: PublicKey,
  wl_token: PublicKey,
  ): Promise<Transaction> {

  let transaction = new Transaction();
  transaction.add(
    await createAddToWLInstruction(
      creator,
      wl_token,
    )
  );
  return transaction;
}
export async function createAddToWLInstruction(
  creator: PublicKey,
  wl_token: PublicKey,
): Promise<TransactionInstruction> {


  let whitelistDataAddress = await getWhitelistDataAddress(wl_token);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("AddToWL", "", null, null, null, null, null, null, null, null, null,null),
    keys: [
      transactionKey(creator, true),

      transactionKey(wl_token, false, false),
      transactionKey(whitelistDataAddress, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),
    ],
  });
}




