import React, { useEffect, useState, useCallback } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RaffleData,
  createBuyTicketsTransaction,
  createRevealWinnerTransaction,
  createRevealWLWinnersTransaction,
  createClaimPrizeTransaction,
  createClaimWLTransaction,
  createCancelRaffleTransaction,
} from "../util/raffle";
import { PublicKey } from "@solana/web3.js";
import moment from "moment";
import "./raffle-details.css";
import logo_white from "../images/logo-white.png";
import nft_img_1 from "../images/956.png";
import { useTimer } from "react-timer-hook";
import Swal from "sweetalert2";
import collection_img from "../images/Group 193.png";
import bacl_arrow_img from "../images/Arrow 3 (Stroke).png";
import flame_img from "../images/Flame.png";
import { PreLoader } from "../components/pre-loader";

import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });

  return (
    <div>
      <div id="countdown" className="countdownHolder">
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const RaffleDetail = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [raffle, setRaffle] = useState({});
  const [noOfTickets, setNoOfTickets] = useState(1);
  const [currentTab, setcurrentTab] = useState("details");
  const [participants, setParticipants] = useState();
  const [showTransactionTable, setShowTransactionTable] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  const navigate = useNavigate();

  const [isPollTimeEnd, setisPollTimeEnd] = useState(false);
  const [isWinnerRevealed, setisWinnerRevealed] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      try {
        let { blockhash } = await connection.getRecentBlockhash();
        transaction.feePayer = publicKey!;
        transaction.recentBlockhash = blockhash;

        let signature = await sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, "confirmed");

        // console.log(signature);

        // if (onChange) onChange();

        return signature;
      } catch (error: any) {
        setIsLoading(false);
        if (error.message !== "User rejected the request.") {
          if (error.message.indexOf("custom program error: 0x1") > -1) {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: "You don't have balance to buy the ticket",
              confirmButtonText: "Close",
            });
          } else if (error.message.indexOf("custom program error: 0x46") > -1) {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: "Raffle is sold out",
              confirmButtonText: "Close",
            });
          } else {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: error.message,
              confirmButtonText: "Close",
            });
          }
        }
      }
    },
    [connection, publicKey, sendTransaction]
  );

  const BuyTickets = useCallback(
    async (raffle, ticket_count) => {
      // console.log(ticket_count)
      if (ticket_count < 1) return;

      if (!publicKey) return;

      // if (ticket_count > raffle["max_tickets_per_wallet"]) {
      //   Swal.fire({
      //     text: "You have exceeded the maximum buying limit for this Raffle.",
      //     confirmButtonText: "Close",
      //   });
      //   return;
      // }

      // if (raffle["entries"].length > 0) {

      let count = raffle["entries"].filter(
        (x) => x === publicKey.toString()
      ).length;
      // console.log(count);
      let a_t = count + ticket_count;
      // if(count > ticket_count){
      //   a_t = count - ticket_count;
      // }else{
      //   a_t =  ticket_count - count
      // }
      if (a_t > raffle["max_tickets_per_wallet"]) {
        Swal.fire({
          text: "You have exceeded the maximum buying limit for this Raffle.",
          confirmButtonText: "Close",
        });
        return;
      }

      if (a_t > raffle["max_entries"]) {
        Swal.fire({
          text: "Please enter less value",
          confirmButtonText: "Close",
        });
        return;
      }
      // }

      setIsLoading(true);
      let sig: any;
      sig = await sendAndConfirmTransaction(
        await createBuyTicketsTransaction(
          connection,
          publicKey,
          localStorage.getItem("raffleword"),
          new PublicKey(localStorage.getItem("ticketToken")),
          Number(ticket_count),
          new PublicKey(localStorage.getItem("a_p"))
        )
      );
      if (sig) {
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "purchaseTicket",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              raffleId: localStorage.getItem("raffleword"),
              walletAddress: publicKey.toString(),
            }),
          }
        );
        let access_key = await access_res.text();

        setIsLoading(false);
        Swal.fire({
          text: "Your ticket purchase is successful.",
          confirmButtonText: "Close",
        });
        get_raffle_details(localStorage.getItem("raffleword"));
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const get_raffle_details = async (raffle_id) => {
    setIsLoading(true);
    let r = await get_raffle(raffle_id);
    let t = await RaffleData(connection, raffle_id);
    // console.log(t);
    t["collectionName"] = r["collectionName"];
    if (r["rafflePriceType"] === "nft") {
      if (r["collectionName"] !== "") {
        let c_r = await get_floor_price(r["collectionName"]);
        t["floorPrice"] = c_r;
      }
    }
    t["id"] = raffle_id;
    t["raffleName"] = r["raffleName"];
    t["raffleType"] = r["raffleType"];
    t["imageData"] = r["imageData"];
    t["rafflePriceType"] = r["rafflePriceType"];
    t["claimedWinners"] = r["claimedWinners"];
    const time = new Date(t.end_timestamp * 1000);

    t["timer"] = time.getTime();
    let now = moment.utc();
    var day = moment.unix(t.end_timestamp);
    var daysDiff = day.diff(now, "days");
    var hoursDiff = day.diff(now, "hours");
    var minutesDiff = day.diff(now, "minutes");
    var secondsDiff = day.diff(now, "seconds");

    if (daysDiff > 0) {
      setisPollTimeEnd(false);
    } else {
      if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
        setisPollTimeEnd(false);
      } else {
        console.log("time end");
        setisPollTimeEnd(true);
      }
    }

    if (r["rafflePriceType"] === "nft") {
      let tokenmetaPubkey = await Metadata.getPDA(
        new PublicKey(t["prize_key"])
      );
      // console.log(tokenmetaPubkey.toString());
      const tokenmeta = await Metadata.load(connection, tokenmetaPubkey);
      // console.log(tokenmeta);
      let img_response = await fetch(tokenmeta["data"]["data"]["uri"]);
      let img = await img_response.json();
      // console.log(img);
      t["nft_image"] = img["image"];
      t["raffleName"] = img["name"];
    }

    if (t["winners"].length > 0) {
      setisWinnerRevealed(true);
    } else {
      setisWinnerRevealed(false);
    }

    setRaffle(t);

    let participant = t["entries"].reduce(function (obj, b) {
      obj[b] = ++obj[b] || 1;
      return obj;
    }, {});
    setParticipants(participant);

    // console.log(participant);

    localStorage.setItem("raffleword", raffle_id);
    localStorage.setItem("ticketToken", t["ticket_mint"]);
    localStorage.setItem("ticketPrice", t["ticket_price"]);
    localStorage.setItem("prize_key", t["prize_key"]);
    localStorage.setItem("a_p", t["account_pubkey"]);
    setIsLoading(false);
    get_transaction(t["account_pubkey"]);
  };

  const get_transaction = async (public_key) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getTransactionsData/" + public_key,
        { mode: "cors" }
      );
      const data = await response.json();
    } catch (e) {
      console.log(e);
    }
  };

  const get_floor_price = async (c_name) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getFloorPrice/" + c_name,
        { mode: "cors" }
      );
      const data = await response.text();
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const get_raffle = async (id) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getRaffle/" + id,
        { mode: "cors" }
      );
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "raffle") {
        let raffle_id = url[4];
        get_raffle_details(raffle_id);
      }
    }
    // get_dao_by_id();
  }, [location]);

  const back_to_raffle_list = async () => {
    navigate("/");
  };

  const change_navigation_tab = async (participants) => {
    setcurrentTab(participants);
    return;
  };

  const find_winner = async () => {
    if (raffle["rafflePriceType"] === "nft") {
      setIsLoading(true);
      RevealWinner();
    } else {
      setIsLoading(true);
      try {
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "fetchData",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              winnerCount: raffle["winner_count"],
              noOfParticipants: raffle["total_entries"],
            }),
          }
        );
        let winners = await access_res.json();

        RevealWLWinners(winners);
      } catch (e) {
        console.log(e);
        Swal.fire({
          text: "Something went to wrong.Please try again later",
          confirmButtonText: "Close",
        });
        setIsLoading(false);
      }
    }
  };

  const randomInteger = async (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const RevealWinner = useCallback(async () => {
    if (!publicKey) return;
    let sig;
    sig = await sendAndConfirmTransaction(
      await createRevealWinnerTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("ticketToken")),
        new PublicKey(localStorage.getItem("a_p"))
      )
    );
    if (sig) {
      Swal.fire({
        text: "Winner has been revealed.",
        confirmButtonText: "Close",
      });
      get_raffle_details(localStorage.getItem("raffleword"));
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  const RevealWLWinners = useCallback(
    async (winner_list) => {
      if (!publicKey) return;
      let sig;
      sig = await sendAndConfirmTransaction(
        await createRevealWLWinnersTransaction(
          connection,
          publicKey,
          localStorage.getItem("raffleword"),
          new PublicKey(localStorage.getItem("ticketToken")),
          winner_list,
          new PublicKey(localStorage.getItem("prize_key")),
          new PublicKey(localStorage.getItem("a_p"))
        )
      );
      if (sig) {
        Swal.fire({
          text: "Winner has been revealed.",
          confirmButtonText: "Close",
        });
        get_raffle_details(localStorage.getItem("raffleword"));
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const claim_winner = async () => {
    ClaimPrize();
    // if (raffle["rafflePriceType"] === "nft") {
    //   ClaimPrize();
    // } else {
    //   ClaimWL();
    // }
  };

  const tab_toggler = async () => {
    setShowTransactionTable(!showTransactionTable);
  };

  const ClaimPrize = useCallback(async () => {
    if (!publicKey) return;
    setIsLoading(true);
    let sig: any;
    sig = await sendAndConfirmTransaction(
      await createClaimPrizeTransaction(
        connection,
        publicKey,
        "630cf0ba7fbbe035e2473152",
        new PublicKey("J3yY5EBAVS6UxxoPf3uCRbxD6Dh7o9t7zgokvp3gNGth"),
        new PublicKey("63kNvDU4BbWtm74PECbX1SGryWg6ZEXk2e3QWdzrfjRP")
      )
    );
    if (sig) {
      // let access_res = await fetch(
      //   process.env.REACT_APP_API_URL + "updateCliamData",
      //   {
      //     method: "POST",
      //     mode: "cors",
      //     headers: new Headers({ "content-type": "application/json" }),
      //     body: JSON.stringify({
      //       raffleId: localStorage.getItem("raffleword"),
      //       walletAddress: publicKey.toString(),
      //     }),
      //   }
      // );
      // let access_key = await access_res.text();

      // get_raffle_details(localStorage.getItem("raffleword"));
      Swal.fire({
        text: "Your prize has been successfully claimed.",
        confirmButtonText: "Close",
      });
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  const ClaimWL = useCallback(async () => {
    if (!publicKey) return;
    setIsLoading(true);
    let sig: any;
    sig = await sendAndConfirmTransaction(
      await createClaimWLTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("a_p"))
      )
    );
    if (sig) {
      let access_res = await fetch(
        process.env.REACT_APP_API_URL + "updateCliamData",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            raffleId: localStorage.getItem("raffleword"),
            walletAddress: publicKey.toString(),
          }),
        }
      );
      let access_key = await access_res.text();
      get_raffle_details(localStorage.getItem("raffleword"));
      Swal.fire({
        text: "Your prize has been successfully claimed.",
        confirmButtonText: "Close",
      });
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  const CancelRaffle = useCallback(async () => {
    if (!publicKey) return;
    await sendAndConfirmTransaction(
      await createCancelRaffleTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("prize_key")),
        new PublicKey(localStorage.getItem("a_p"))
      )
    );
  }, [connection, publicKey, sendAndConfirmTransaction]);

  return (
    <>
      <div className="desk-top-view">
        {isLoading && <PreLoader />}
        {!isLoading && (
          <>
            <div className="search-container-raffle-detail pb-5 card rounded-0 bgi-no-repeat bgi-position-x-end bgi-size-cover mb-9">
              <div className="container-xxl">
                <div className="row gy-0 gx-10">
                  <div className="col-xl-3 col-md-12">
                    <div
                      className="d-flex pointer"
                      onClick={() => back_to_raffle_list()}
                    >
                      <div className="py-2">
                        <div className="back-arrow">
                          <img src={bacl_arrow_img} />
                        </div>
                      </div>

                      <div className="min-w-100px w-100 py-2 px-4 mb-3">
                        <span className="raffle-detail-label">Back to</span>

                        <div className="raffle-detail-text">All Raffle</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-12"></div>
                </div>
                <div className="row gy-0 gx-10">
                  <div className="col-xl-3 col-md-12">
                    {raffle["rafflePriceType"] === "nft" ? (
                      <>
                        {raffle["nft_image"] !== null ? (
                          <img
                            alt="raffle_image"
                            src={raffle["nft_image"]}
                            className="raffle-image"
                          />
                        ) : (
                          <img
                            alt="raffle_image"
                            src={nft_img_1}
                            className="raffle-image"
                          />
                        )}
                      </>
                    ) : raffle["imageData"] !== null ? (
                      <img
                        alt="raffle_imagedd"
                        src={"data:image/png;base64," + raffle["imageData"]}
                        className="raffle-image"
                      />
                    ) : (
                      <img
                        alt="raffle_image"
                        src={nft_img_1}
                        className="raffle-image"
                      />
                    )}
                    {isMobile && (
                      <div className="">
                        <div className="py-2 px-4 me-6 mb-3">
                          {raffle["rafflePriceType"] === "nft" ? (
                            <div className="detail-page-collection-name text-left pl-0">
                              {raffle["collectionName"]}{" "}
                              <img
                                className="collection-img"
                                src={collection_img}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="raffle-detail-text fw-bold mb-0">
                            {raffle["raffleName"]}
                          </div>
                        </div>
                        {raffle["collectionName"] !== "" &&
                        raffle["collectionName"] !== null ? (
                          <div className="min-w-100px w-100 py-2 px-4 me-6 mb-3">
                            <span className="raffle-detail-label text-yellow">
                              Floor Price
                            </span>

                            <div className="raffle-detail-text">
                              {raffle["floorPrice"]} ◎
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-xl-9 col-md-12">
                    <div className="card raffle-detail-card">
                      <div className="card-body">
                        <div className={`${isMobile ? "" : "d-flex"}`}>
                          <div className="min-w-100px w-100 py-2 px-4 me-6 mb-3">
                            <span className="raffle-detail-label">
                              {isPollTimeEnd
                                ? "Ticket Sales Ended On"
                                : "Ticket Sales Ends In"}
                            </span>

                            <div className="raffle-detail-text">
                              {!isPollTimeEnd &&
                                raffle["end_timestamp"] !== undefined && (
                                  <>
                                    {" "}
                                    <MyTimer
                                      expiryTimestamp={raffle["timer"]}
                                    />
                                  </>
                                )}
                              {isPollTimeEnd && (
                                <>
                                  {moment
                                    .unix(raffle["end_timestamp"])
                                    .format("MMMM DD, YYYY")}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="min-w-100px w-100 py-2 px-4 mb-3">
                            <span className="raffle-detail-label">
                              Ticket Cost
                            </span>

                            <div className="raffle-detail-text">
                              {raffle["ticket_price"] / 1} VAULT
                            </div>
                          </div>
                          <div className="min-w-100px w-100 py-2 px-4 mb-3">
                            <span className="raffle-detail-label">
                              Maximum Ticket Per Wallet
                            </span>

                            <div className="raffle-detail-text">
                              {raffle["max_tickets_per_wallet"]}
                            </div>
                          </div>
                        </div>
                        <div className={`${isMobile ? "" : "d-flex"}`}>
                          <div className="min-w-100px w-100 py-2 px-4 me-6 mb-3">
                            <span className="raffle-detail-label">
                              Raffle Started Date
                            </span>

                            <div className="raffle-detail-text">
                              {moment
                                .unix(raffle["timestamp"])
                                .format("MMMM DD, YYYY")}
                            </div>
                          </div>
                          <div className="min-w-100px w-100 py-2 px-4 mb-3">
                            <span className="raffle-detail-label text-yellow">
                              Ticket Sold
                            </span>

                            <div className="raffle-detail-text">
                              {raffle["total_entries"]}/{raffle["max_entries"]}
                            </div>
                          </div>

                          <div className="min-w-100px w-100 py-2 px-4 mb-3"></div>
                        </div>
                        <div className={`${isMobile ? "" : "d-flex"}`}></div>
                        {!isPollTimeEnd &&
                          publicKey &&
                          publicKey.toString() !== raffle["creator"] && (
                            <div className="row mb-2">
                              {raffle["total_entries"] !==
                                raffle["max_entries"] && (
                                <>
                                  <div className={`${isMobile ? "col-4" : "col-2"}`}>
                                    <input
                                      type="number"
                                      className="form-control ticket-price-box form-control-solid text-center"
                                      onChange={(e) =>
                                        setNoOfTickets(Number(e.target.value))
                                      }
                                      placeholder="Qty"
                                      value={noOfTickets}
                                    />
                                  </div>
                                  <div className="col">
                                    <button
                                      className="btn btn-raffle-detail"
                                      onClick={() =>
                                        BuyTickets(raffle, noOfTickets)
                                      }
                                    >
                                      Buy Raffle Ticket
                                    </button>
                                  </div>
                                </>
                              )}
                              {raffle["total_entries"] ===
                                raffle["max_entries"] && (
                                <div className="col">
                                  <button className="btn btn-raffle-detail">
                                    Ticket Sold
                                  </button>
                                </div>
                              )}
                            </div>
                          )}

                        {raffle["entries"]?.length > 0 &&
                          !isWinnerRevealed &&
                          isPollTimeEnd &&
                          publicKey &&
                          publicKey.toString() === raffle["creator"] && (
                            <div className="row mb-2">
                              <div className="col">
                                <button
                                  className="btn btn-raffle-detail"
                                  onClick={find_winner}
                                >
                                  Reveal Winners
                                </button>
                              </div>
                            </div>
                          )}
                        {raffle["entries"]?.length === 0 &&
                          !isWinnerRevealed &&
                          isPollTimeEnd &&
                          publicKey &&
                          publicKey.toString() === raffle["creator"] && (
                            <div className="row mb-2">
                              <div className="col">
                                <button
                                  className="btn btn-raffle-detail"
                                  onClick={CancelRaffle}
                                >
                                  Cancel Raffle
                                </button>
                              </div>
                            </div>
                          )}

                        {isWinnerRevealed &&
                          isPollTimeEnd &&
                          publicKey &&
                          raffle["winners"] !== undefined &&
                          raffle["winners"].includes(publicKey.toString()) && (
                            <div className="row mb-2">
                              <div className="col">
                                {raffle["claimedWinners"].includes(
                                  publicKey.toString()
                                ) ? (
                                  <div className="text-yellow fs-4 mb-2">
                                    Congratulations!. You won the raffle and
                                    Your claim was successful
                                  </div>
                                ) : (
                                  <>
                                    <div className="text-yellow fs-4 mb-2">
                                      Congratulations! You Won the Raffle.{" "}
                                    </div>
                                    <button
                                      className="btn btn-raffle-detail"
                                      onClick={claim_winner}
                                    >
                                      Claim Prize
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {!isMobile && (
                  <div className="row gy-0 gx-10">
                    <div className="col-3">
                      <div className="d-flex">
                        <div className="py-2 px-4 me-6 mb-3">
                          {raffle["rafflePriceType"] === "nft" ? (
                            <div className="detail-page-collection-name text-left pl-0">
                              {raffle["collectionName"]}{" "}
                              <img
                                className="collection-img"
                                src={collection_img}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="raffle-detail-text fw-bold mb-0">
                            {raffle["raffleName"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      {raffle["collectionName"] !== "" &&
                      raffle["collectionName"] !== null ? (
                        <div className="min-w-100px w-100 py-2 px-4 me-6 mb-3">
                          <span className="raffle-detail-label text-yellow">
                            Floor Price
                          </span>

                          <div className="raffle-detail-text">
                            {raffle["floorPrice"]} ◎
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="container-custom container-xxl d-lg-flex flex-column w-100">
              {raffle["winners"] !== undefined && raffle["winners"].length > 0 && (
                <div className="row gy-0 gx-10 winner-data">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="fw-bold text-white recent-raffle-text">
                      <img className="flame-img" src={flame_img} /> Winners
                      <img className="flame-img" src={flame_img} />
                    </span>
                    <span className="text-white fw-bolder">
                      (Note: Winner should claim the prize with in 24 hours)
                    </span>
                  </h3>
                  <div className="col-12">
                    <div className="raffle-details-table-card card card-xl-stretch mb-5 mb-xl-8">
                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted">

                                <th className="text-yellow min-w-150px">
                                  Wallet Address
                                </th>

                                <th className="text-blue">Claim</th>
                              </tr>
                            </thead>
                            {raffle["winners"] !== undefined &&
                              raffle["winners"].length > 0 && (
                                <tbody>
                                  {raffle["winners"].map((t, k) => (
                                    <tr>

                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="d-flex justify-content-start flex-column">
                                            <div className="text-white fw-bold text-hover-primary fs-4">
                                              {t}
                                            </div>
                                          </div>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="text-yellow fw-bold text-hover-primary fs-4">
                                          {" "}
                                          {raffle["claimedWinners"].includes(t)
                                            ? "Claimed"
                                            : "Not Claimed"}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row gy-0 gx-10 participants-section">
                <h3 className="card-title align-items-start flex-column">
                  <span className="fw-bold text-white recent-raffle-text">
                    <img className="flame-img" src={flame_img} /> Participants
                    <img className="flame-img" src={flame_img} />
                  </span>
                </h3>
                {/* <div className="slider-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={() => tab_toggler()}
                    id="toggle_vat"
                    className="toggle_vat"
                  />
                  <div className="slider round">
                    <span className="slider_text">
                      <span className="off">Participants</span>
                      <span className="on">Transactions</span>
                    </span>
                  </div>
                </label>
              </div> */}
                <div className="col-12">
                  <div className="raffle-details-table-card card card-xl-stretch mb-5 mb-xl-8">
                    <div className="card-body py-3">
                      {showTransactionTable && (
                        <div className="table-responsive participant-table">
                          <table className="table align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted">
                                {/* <th className="text-blue">#</th> */}
                                <th className="text-yellow min-w-150px">
                                  Wallet
                                </th>
                                <th className="text-blue">Tickets Bought</th>
                              </tr>
                            </thead>
                            {participants && (
                              <tbody>
                                {Object.entries(participants).map((t, k) => (
                                  <tr>
                                    {/* <td className="text-white">{k + 1}</td> */}
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="d-flex justify-content-start flex-column">
                                          <div className="text-white fw-bold text-hover-primary fs-4">
                                            {t[0]}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-left text-white fw-bold text-hover-primary d-block fs-4">
                                        {t[1]}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            )}
                          </table>
                        </div>
                      )}
                      {!showTransactionTable && (
                        <div className="table-responsive">
                          <table className="table align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted">
                                {/* <th className="text-blue">#</th> */}
                                <th className="text-yellow min-w-150px">
                                  TXN Number
                                </th>
                                <th className="text-blue">Buyer</th>
                                <th className="text-blue">Date</th>
                                <th className="text-blue">Tickets</th>
                              </tr>
                            </thead>
                            {participants && (
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex justify-content-start flex-column">
                                        <div className="text-white fw-bold text-hover-primary fs-6">
                                          smmjd152...GFBhdiuh
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left text-white fw-bold text-hover-primary d-block fs-4">
                                      8fuhbfzb...UHJdandadn
                                    </div>
                                  </td>
                                  <td>15th Aug, 22 | 11:50:00 PM</td>
                                  <td>10</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="mobile-view text-white fs-2">
        <img src={logo_white} />
        <div>Please use this app in desktop for better experience</div>
      </div>
    </>
  );
};
