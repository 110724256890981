import React, { useCallback, useState, useEffect, useRef } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { LAMPORTS_PER_SOL, PublicKey, Keypair } from "@solana/web3.js";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Modal } from "react-bootstrap";
import S3 from "react-aws-s3";

import {
  createRaffleInitTransaction,
  RaffleData,
  getAllTokens,
  getAllNFT,
} from "../util/raffle";

import "./create-raffle.css";

const hourMilliSeconds = 1000 * 3600;

const addDays = (d, num) => {
  let time = d.getTime();
  time += hourMilliSeconds * 24 * num;
  return new Date(time);
};

const getNearestHour = () => {
  const now = new Date().getTime();
  return new Date(now - (now % hourMilliSeconds) + hourMilliSeconds);
};

export const CreateRaffle = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const navigate = useNavigate();
  const [raffleType, setraffleType] = useState("");
  const [raffleFor, setraffleFor] = useState("");
  const [raffleWord, setraffleWord] = useState("");
  const [raffleName, setraffleName] = useState("sswesd");
  const [ticketSupply, setticketSupply] = useState("");
  const [ticketPrice, setticketPrice] = useState("");
  const [maxTicketPerWallet, setmaxTicketPerWallet] = useState("");
  const [winnerCount, setwinnerCount] = useState("");
  const [priceAmountPerWinner, setpriceAmountPerWinner] = useState("");
  const [endTime, setendTime] = useState(addDays(getNearestHour(), 0));
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [disabledHours, setDisabledHours] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  const [whitelistedToken, setwhitelistedToken] = useState([]);
  const [selectedToken, setselectedToken] = useState("");
  const [selectedNFTCmid, setselectedNFTCmid] = useState("");

  const [NFTModal, setNFTModal] = useState(false);
  const [TokenModal, setTokenModal] = useState(false);
  const [file, setFile] = useState();
  const fileInput: any = useRef();
  const [fileNameFrReq, setFileNameFrReq] = useState();
  // const ticket_token = new PublicKey(
  //   "2a2aGyHFkGqsDeK8T9WHqsF7MYa7wJ9XWhfvKAixehDj"

  const ticket_token = new PublicKey(
    "2a2aGyHFkGqsDeK8T9WHqsF7MYa7wJ9XWhfvKAixehDj"
  ); // players will pay to buy tickets like DUST, FORGE
  const prize_token = new PublicKey(
    "FAHSEQFhMbSxmJ4ZJx3RmN5rDDHgsZGjZpKTsAu7XzrL"
  ); // Prize token

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, "confirmed");

      console.log(signature);

      // if (onChange) onChange();

      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  const sendAndConfirmAccountTransaction = useCallback(
    async (transaction, dataAddress: Keypair) => {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;
      transaction.sign(dataAddress);

      let signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, "confirmed");
      console.log(signature);
      // if (onChange) onChange();
      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  let moveStep = async (e) => {
    console.log(e);
    if (e === "step1") {
      setStep1(true);
      setStep2(false);
    } else if (e === "step2") {
      if (raffleFor === "wallet" || selectedToken !== "") {
        if (raffleFor !== "nft") {
          let file = fileInput!.current.files[0];
          setFile(file);

          let newFileName = fileInput!.current.files[0].name.replace(
            /\..+$/,
            ""
          );
          newFileName = Math.random() + "_" + file.name;
          setFileNameFrReq(newFileName);
        }
        if (raffleFor === "nft") {
          await setwinnerCount("1");
          await setpriceAmountPerWinner("1");
        }

        setStep1(false);
        setStep2(true);
      } else {
        Swal.fire({
          text: "Please add whitelisted NFT/Token/Wallet",
          confirmButtonText: "Close",
        });
      }
    }
  };

  let onRaffleChange = async (e) => {
    console.log(e);
    setselectedToken("");
    setraffleType(e);
  };

  let onRaffleTypeChange = async (e) => {
    console.log(e);
    setraffleFor(e);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(votingEndTime.toISOString());
    let isfileRquired = false;
    if (raffleFor === "token" || raffleFor === "wallet") {
      if (file) {
        isfileRquired = true;
      } else {
        isfileRquired = false;
      }
    } else {
      isfileRquired = true;
    }

    if (
      ticketSupply !== "" &&
      ticketPrice !== "" &&
      maxTicketPerWallet !== "" &&
      // winnerCount !== "" &&
      // priceAmountPerWinner !== "" &&
      isfileRquired
    ) {
      console.log(
        raffleType,
        raffleName,
        ticketSupply,
        ticketPrice,
        maxTicketPerWallet,
        winnerCount,
        priceAmountPerWinner
      );

      let endTimeasms = Math.floor(moment(endTime).valueOf() / 1000);
      console.log(selectedToken);
      let raffle_word = await get_raffle_word();
      localStorage.setItem("raffleType", raffleType);
      localStorage.setItem("raffleword", raffle_word);
      localStorage.setItem("raffleName", raffleName);
      localStorage.setItem("raffleFor", raffleFor);
      localStorage.setItem("ticketSupply", ticketSupply);
      localStorage.setItem("ticketPrice", ticketPrice);
      localStorage.setItem("maxTicketPerWallet", maxTicketPerWallet);
      localStorage.setItem("winnerCount", winnerCount);
      localStorage.setItem("priceAmountPerWinner", priceAmountPerWinner);
      localStorage.setItem("prize_token", selectedToken);
      if (raffleFor === "nft") {
        localStorage.setItem("fileNameFrReq", "");
        localStorage.setItem("cmid", selectedNFTCmid);
        localStorage.setItem("nftId", selectedToken);
      } else {
        localStorage.setItem("nftId", "");
        localStorage.setItem("fileNameFrReq", fileNameFrReq);
        if (file) {
          const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            //   dirName: process.env.REACT_APP_DIR_NAME /* optional */,
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: process.env.REACT_APP_S3URL,
          };

          const ReactS3Client = new S3(config);
          ReactS3Client.uploadFile(file, fileNameFrReq).then((data) => {
            if (data.status === 204) {
              console.log("success");
            } else {
              console.log("fail");
            }
          });
        }
      }
      localStorage.setItem("endTime", endTimeasms.toString());

      await RaffleInit();
    } else {
      Swal.fire({
        title: "Empty or invalid form fields",
        text: "Please review all the fields",
        confirmButtonText: "Close",
      });
    }
  };

  const RaffleInit = useCallback(async () => {
    if (!publicKey) return;
    var dataAddress;
    dataAddress = Keypair.generate();

    let isDao = 0;
    let hasEscrow = 0;
    let checkWLtoken;
    let prize_token;
    let prize_amount;
    if (localStorage.getItem("raffleType") === "dao") {
      isDao = 1;
    }
    if (localStorage.getItem("raffleFor") === "token") {
      hasEscrow = 1;
      checkWLtoken = new PublicKey(localStorage.getItem("prize_token"));
      prize_token = new PublicKey(localStorage.getItem("prize_token"));
      prize_amount =
        Number(localStorage.getItem("priceAmountPerWinner")) * 1;
    }
    if (localStorage.getItem("raffleFor") === "nft") {
      hasEscrow = 1;
      console.log(localStorage.getItem("cmid"));
      checkWLtoken = new PublicKey(localStorage.getItem("cmid"));
      prize_token = new PublicKey(localStorage.getItem("prize_token"));
      prize_amount = Number(localStorage.getItem("priceAmountPerWinner"));
    }
    if (localStorage.getItem("raffleFor") === "wallet") {
      checkWLtoken = ticket_token;
      prize_token = ticket_token;
      prize_amount = Number(localStorage.getItem("priceAmountPerWinner"));
    }
    let sig: any;
    sig = await sendAndConfirmAccountTransaction(
      await createRaffleInitTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        Number(localStorage.getItem("winnerCount")),
        prize_amount,
        localStorage.getItem("endTime"),
        Number(localStorage.getItem("ticketSupply")),
        Number(localStorage.getItem("ticketPrice")) * 1,
        Number(localStorage.getItem("maxTicketPerWallet")),
        isDao,
        hasEscrow,
        ticket_token,
        prize_token,
        dataAddress.publicKey,
        checkWLtoken
      ),
      dataAddress
    );
    if (sig) {
      let access_res = await fetch(
        process.env.REACT_APP_API_URL + "updateRaffle",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            raffleWord: localStorage.getItem("raffleword"),
            raffleName: localStorage.getItem("raffleName"),
            raffleType: localStorage.getItem("raffleType"),
            attachemnet: localStorage.getItem("fileNameFrReq"),
            transactionHash: sig,
            rafflePriceType: localStorage.getItem("raffleFor"),
            creatorWalletAddress: publicKey.toString(),
            daoName: "string",
            nftId: localStorage.getItem("nftId"),
          }),
        }
      );
      let access_key = await access_res.json();
      Swal.fire({
        text: "Your Raffle has been created.",
        confirmButtonText: "Click to view Raffle",
      }).then((sig) => {
        console.log(sig);
        navigate("/raffle/" + localStorage.getItem("raffleword"), {
          replace: true,
        });
      });
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  const handleCloseNFTModal = () => {
    setNFTModal(false);
    setselectedToken("");
    setraffleFor("");
  };
  const handleCloseTokenModal = () => {
    setTokenModal(false);
    setselectedToken("");
    setraffleFor("");
  };

  const get_raffle_word = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getRaffleWord",
        { mode: "cors" }
      );
      const data = await response.text();

      setraffleWord(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitTokenModal = () => {
    if (selectedToken === "") {
      Swal.fire({
        text: "Please select the token to create raffle",
        confirmButtonText: "Close",
      });
    } else {
      setTokenModal(false);
      setNFTModal(false);
    }
  };

  let onChooseToken = async (type) => {
    console.log(type);
    setraffleFor(type);
    let res;
    if (type === "nft") {
      res = await getAllNFT(connection, publicKey);
      console.log(res);
      setwhitelistedToken(res);
    } else if (type === "token") {
      res = await getAllTokens(connection, publicKey);
      console.log(res);
      setwhitelistedToken(res);
    } else if (type === "wallet") {
    }
  };

  const handleImageUpload = (event) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0].name);
      console.log(fileInput!.current.files[0]);
    }
  };

  // useEffect(() => {
  //   async function data() {
  //     await get_raffle_word()
  //   }
  //   data();
  // },[]);

  return (
    <>
      <Modal show={NFTModal} onHide={handleCloseNFTModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Select Nft</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row g-3 g-lg-6">
              {whitelistedToken.map((token, index) => (
                <div className="col-md-4">
                  <a>
                    <input
                      type="radio"
                      name="emotion"
                      id={token["address"]}
                      className="input-hidden"
                      onChange={() => {
                        setselectedToken(token["address"]);
                        setselectedNFTCmid(token["cmid"]);
                      }}
                    />
                    <label htmlFor={token.address}>
                      <img alt="" height="150" src={token?.image} width="150" />
                    </label>
                    <div className="text-center m-5">
                      <span className="fs-6 text-gray-800 text-hover-primary fw-bold">
                        {token?.meta_data?.data?.name}
                      </span>
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <div className="text-center mt-9">
              <button
                type="reset"
                className="btn btn-light me-3"
                onClick={handleCloseTokenModal}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitTokenModal}
                type="button"
                className="btn btn-primary"
                data-kt-modal-action-type="submit"
              >
                <span className="indicator-label">Select</span>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={TokenModal} onHide={handleCloseTokenModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Select Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row g-3 g-lg-6">
              {whitelistedToken.map((token, index) => (
                <div className="col-md-4">
                  <a>
                    <input
                      type="radio"
                      name="emotion"
                      id={token["address"]}
                      className="input-hidden"
                      onChange={() => setselectedToken(token["address"])}
                    />
                    <label htmlFor={token.address}>
                      <img alt="" height="150" src={token?.image} width="150" />
                    </label>
                    <div className="text-center m-5">
                      <span className="fs-6 text-gray-800 text-hover-primary fw-bold wl-token-name">
                        {token?.name}
                      </span>
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <div className="text-center mt-9">
              <button
                type="reset"
                className="btn btn-light me-3"
                onClick={handleCloseTokenModal}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitTokenModal}
                type="button"
                className="btn btn-primary"
                data-kt-modal-action-type="submit"
              >
                <span className="indicator-label">Select</span>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div
        id="kt_content_container"
        className="d-flex flex-column-fluid align-items-start container-xxl"
      >
        <div
          className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
          id="kt_create_account_stepper"
          data-kt-stepper="true"
        >
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto card">
                <form
                  onSubmit={handleSubmit}
                  className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                  id="kt_create_account_form"
                >
                  {step1 && (
                    <div className="current step-1">
                      <div className="w-100">
                        <div className="pb-10 pb-lg-15">
                          <h2 className="fw-bold d-flex align-items-center text-white">
                            Create Raffle
                          </h2>
                        </div>
                        <div
                          className="fv-row mb-8 fv-plugins-icon-container"
                          data-select2-id="select2-data-122-epah"
                        >
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2 text-blue">
                            <span className="required">Raffle Type</span>
                          </label>

                          <select
                            className="form-select form-select-solid"
                            name="created_for"
                            onChange={(e) => onRaffleChange(e.target.value)}
                          >
                            {/* <option value="seller">Seller</option> */}
                            <option>Select raffle type</option>
                            <option value="individual">Individual</option>
                            <option value="dao">DAO</option>
                          </select>

                          <div className="fv-plugins-message-container invalid-feedback"></div>
                        </div>

                        <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          {(raffleType === "individual" ||
                            raffleType === "dao") && (
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2  text-blue">
                              <span className="required">
                                Raffle Price Type
                              </span>
                            </label>
                          )}

                          <div className="row">
                            {(raffleType === "individual" ||
                              raffleType === "dao") && (
                              <div
                                className="col-lg-4"
                                onClick={() => {
                                  setNFTModal(true);
                                  onChooseToken("nft");
                                }}
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="account_type"
                                  value="nft"
                                  id="individual"
                                  checked={raffleFor === "nft"}
                                />
                                <label
                                  className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10"
                                  htmlFor="kt_create_account_form_account_type_personal"
                                >
                                  <span className="svg-icon svg-icon-3x me-5">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        opacity="0.3"
                                        d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>

                                  <span className="d-block fw-semibold text-start">
                                    <span className="text-white fw-bold d-block fs-4 mb-2">
                                      Choose NFT
                                    </span>
                                  </span>
                                </label>

                                <div className="fv-plugins-message-container invalid-feedback"></div>
                              </div>
                            )}
                            {(raffleType === "individual" ||
                              raffleType === "dao") && (
                              <div
                                className="col-lg-4"
                                onClick={() => {
                                  setTokenModal(true);
                                  onChooseToken("token");
                                }}
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="account_type"
                                  value="dao"
                                  checked={raffleFor === "token"}
                                  id="token"
                                />
                                <label
                                  className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                                  htmlFor="kt_create_account_form_account_type_corporate"
                                >
                                  <span className="svg-icon svg-icon-3x me-5">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>

                                  <span className="d-block fw-semibold text-start">
                                    <span className="text-white fw-bold d-block fs-4 mb-2">
                                      Choose Token
                                    </span>
                                  </span>
                                </label>
                              </div>
                            )}
                            {raffleType === "dao" && (
                              <div
                                className="col-lg-4"
                                onClick={() => {
                                  onChooseToken("wallet");
                                }}
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="account_type"
                                  value="wallet"
                                  checked={raffleFor === "wallet"}
                                  id="wallet"
                                />
                                <label
                                  className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                                  htmlFor="kt_create_account_form_account_type_corporate"
                                >
                                  <span className="svg-icon svg-icon-3x me-5">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>

                                  <span className="d-block fw-semibold text-start">
                                    <span className="text-white fw-bold d-block fs-4 mb-2">
                                      Add <br />
                                      Wallet
                                    </span>
                                  </span>
                                </label>
                              </div>
                            )}
                          </div>
                          {/* {selectedToken === "" && (
                            <div
                              className="row mb-2"
                              data-kt-buttons="true"
                              data-kt-initialized="1"
                            >
                              {(raffleType === "individual" ||
                                raffleType === "dao") && (
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    id="kt_file_manager_new_folder"
                                    onClick={() => {
                                      setTokenModal(true);
                                      onChooseToken("nft");
                                    }}
                                  >
                                    Add NFT
                                  </button>
                                </div>
                              )}
                              {(raffleType === "individual" ||
                                raffleType === "dao") && (
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    id="kt_file_manager_new_folder"
                                    onClick={() => {
                                      setTokenModal(true);
                                      onChooseToken("token");
                                    }}
                                  >
                                    Add Whitlisted Token
                                  </button>
                                </div>
                              )}
                              {raffleType === "dao" && (
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    id="kt_file_manager_new_folder"
                                    onClick={() => {
                                      setTokenModal(true);
                                      onChooseToken("wallet");
                                    }}
                                  >
                                    Add Whitlisted Wallat
                                  </button>
                                </div>
                              )}
                            </div>
                          )} */}
                          {selectedToken !== "" && (
                            <div className="row mb-7">
                              <div className="col-lg-12">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2 text-blue">
                                  <span className="required">
                                    Selected Token
                                  </span>
                                </label>

                                <div className="fs-5 text-white">
                                  {selectedToken}
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <div className="mt-5">
                            {raffleFor === "NFT" && (
                              <button
                                type="button"
                                className="btn btn-primary me-3"
                                id="kt_file_manager_new_folder"
                                onClick={() => setNFTModal(true)}
                              >
                                <span className="svg-icon svg-icon-2">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      opacity="0.3"
                                      d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                                Add NFT
                              </button>
                            )}
                             {raffleFor === "whitelist_by_token" && (
                              <button
                                type="button"
                                className="btn btn-primary me-3"
                                id="kt_file_manager_new_folder"
                                onClick={() => {setTokenModal(true); onChooseToken();}}
                              >
                                <span className="svg-icon svg-icon-2">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      opacity="0.3"
                                      d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                                Add Token
                              </button>
                            )}
                          </div> */}
                          {raffleFor !== "nft" && (
                            <div className="row row-cols-1 row-cols-sm-1 rol-cols-md-1 row-cols-lg-1 ">
                              <div className="col">
                                <div className="image-upload d-flex flex-column fv-row mb-8">
                                  <label htmlFor="file-input text-left mb-7 text-blue">
                                    <span className="fs-6 fw-bold required text-blue">
                                      Raffle Image/Gif
                                    </span>
                                  </label>

                                  <input
                                    id="file-input"
                                    type="file"
                                    ref={fileInput}
                                    onChange={(event) =>
                                      handleImageUpload(event)
                                    }
                                    accept=".zip,.rar,.7zip,application/pdf, application/vnd.ms-excel, image/*"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {step2 && (
                    <div className="pending step-2">
                      <div className="w-100">
                        <div className="pb-10 pb-lg-15">
                          <h2 className="fw-bold text-white">Create Raffle </h2>
                        </div>
                        {(raffleFor === "token" || raffleFor === "wallet") && (
                          <div className="row fv-row mb-7 fv-plugins-icon-container">
                            <div className="col-xl-12">
                              <label className="form-label mb-3 text-blue">
                                Raffle Name
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) => setraffleName(e.target.value)}
                                placeholder=""
                              />
                            </div>
                          </div>
                        )}

                        <div className="row fv-row mb-7 fv-plugins-icon-container">
                          <div className="col-xl-6">
                            <label className="form-label mb-3  text-blue">
                              Ticket Size
                            </label>

                            <input
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              onChange={(e) => setticketSupply(e.target.value)}
                              placeholder=""
                            />
                          </div>
                          <div className="col-xl-6">
                            <label className="form-label mb-3  text-blue">
                              Ticket Price
                            </label>
                            <div className="d-flex align-items-center">
                              <input
                                type="text"
                                className="form-control raffle-price-text form-control-lg form-control-solid w-100px"
                                onChange={(e) => setticketPrice(e.target.value)}
                                placeholder=""
                              />
                              {/* <select
                                className="raffle-price-select form-select form-select-solid"
                                name="created_for"
                                onChange={(e) => onRaffleChange(e.target.value)}
                              >

                                <option value="dao">USDC</option>
                                <option value="dao">FORGE</option>
                                <option value="dao">YAKU</option>
                                <option value="dao">vBLSH</option>
                                <option value="dao">DUST</option>
                                <option value="dao">DAWG</option>
                              </select> */}
                            </div>
                          </div>
                        </div>
                        <div className="row fv-row mb-7 fv-plugins-icon-container">
                          <div className="col-xl-6">
                            <label className="form-label mb-3 text-blue">
                              Max Ticket Per Wallet
                            </label>

                            <input
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              onChange={(e) =>
                                setmaxTicketPerWallet(e.target.value)
                              }
                              placeholder=""
                            />
                          </div>

                          <div className="col-xl-6">
                            <label className="form-label mb-3 text-blue">
                              Raffle End Date
                            </label>

                            <DatePicker
                              minDate={moment().toDate()}
                              timeInputLabel="Time:"
                              showTimeInput
                              minTime={disabledHours}
                              maxTime={setHours(setMinutes(new Date(), 0), 23)}
                              className="form-control form-control-solid"
                              dateFormat="MM/dd/yyyy h:mm aa"
                              selected={endTime}
                              onChange={(date) => setendTime(date)}
                            />
                          </div>
                        </div>
                        {(raffleFor === "token" || raffleFor === "wallet") && (
                          <div className="row fv-row mb-7 fv-plugins-icon-container">
                            <div className="col-xl-6">
                              <label className="form-label mb-3  text-blue">
                                Price Amount for winner
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) =>
                                  setpriceAmountPerWinner(e.target.value)
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="col-xl-6">
                              <label className="form-label mb-3  text-blue">
                                Winner Count
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) => setwinnerCount(e.target.value)}
                                placeholder=""
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="d-flex flex-stack pt-15">
                    <div className="mr-2">
                      {step1 && (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          onClick={() => moveStep("step2")}
                        >
                          Continue
                          <span className="svg-icon svg-icon-4 ms-1">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x="18"
                                y="13"
                                width="13"
                                height="2"
                                rx="1"
                                transform="rotate(-180 18 13)"
                                fill="currentColor"
                              ></rect>
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      )}
                      {step2 && (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn back-btn me-3"
                            onClick={() => moveStep("step1")}
                          >
                            Back
                          </button>
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            onClick={handleSubmit}
                          >
                            Create Raffle
                            <span className="svg-icon svg-icon-4 ms-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="18"
                                  y="13"
                                  width="13"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-180 18 13)"
                                  fill="currentColor"
                                ></rect>
                                <path
                                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
