import React, { useState, useCallback } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import Swal from "sweetalert2";
import { createAddToWLTransaction } from "../util/raffle";

export const WhiteList = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [whitelist_value, setwhitelist_value] = useState("");
  let handleSubmit = async (e) => {
    e.preventDefault();
    if (whitelist_value !== "") {
      console.log("ddd")
      let res = await AddToWL(whitelist_value);
      console.log(res);
    } else {
      Swal.fire({
        title: "Empty or invalid form fields",
        text: "Please review all the fields",
        confirmButtonText: "Close",
      });
    }
  };

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, "confirmed");

      console.log(signature);

      // if (onChange) onChange();

      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  const AddToWL = useCallback(
    async (w_l) => {
      if (!publicKey) return;
      console.log(publicKey.toString(), w_l)
      let t = await sendAndConfirmTransaction(
        await createAddToWLTransaction(
          connection,
          publicKey,
          new PublicKey(w_l)
        )
      );
      console.log(t)
      if(t){
        Swal.fire({
          text: "Whitelist has successful",
          confirmButtonText: "Close",
        });
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  return (
    <>
      <div
        id="kt_content_container"
        className="d-flex flex-column-fluid align-items-start container-xxl"
      >
        <div
          className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
          id="kt_create_account_stepper"
          data-kt-stepper="true"
        >
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto card">
                <form
                  onSubmit={handleSubmit}
                  className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                  id="kt_create_account_form"
                >
                  <div className="w-100">
                    <div className="row fv-row mb-7 fv-plugins-icon-container">
                    <div className="col-12">
                        <label className="form-label mb-3">
                          To be whitelist
                        </label>

                        <select
                          className="form-select form-select-solid"
                          name="created_for"
                          // onChange={(e) => handleTokenFormChange(e)}
                        >
                          {/* <option value="seller">Seller</option> */}
                          <option value="dao">DAO</option>
                          <option value="nft">NFT</option>
                          <option value="token">Token</option>
                          <option value="wallet">Wallet</option>
                        </select>
                      </div>
                      <div className="col-12 mt-5">
                        <label className="form-label mb-3">
                          Address
                        </label>

                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          onChange={(e) => setwhitelist_value(e.target.value)}
                          placeholder=""
                        />
                      </div>

                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary me-3 text-center"
                      onClick={handleSubmit}
                    >
                      Whitelist
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
