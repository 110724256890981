import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import "./components.css";

export const PreLoader: FC = () => {
  return (
    <>
      <div className="loader"></div>
    </>
  );
};
