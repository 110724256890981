import React, { useEffect, useState, useCallback } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RaffleData,
  createBuyTicketsTransaction,
  createRevealWinnerTransaction,
  createRevealWLWinnersTransaction,
  createClaimPrizeTransaction,
  createCancelRaffleTransaction,
} from "../util/raffle";
import { PublicKey } from "@solana/web3.js";
import moment from "moment";
import "./my-account.css";
import logo_white from "../images/logo-white.png";
import nft_img_1 from "../images/956.png";
import my_account_img from "../images/Rectangle 31.png";
import flame_img from "../images/Flame.png";
import { useTimer } from "react-timer-hook";
import Swal from "sweetalert2";
import { PreLoader } from "../components/pre-loader";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });

  return (
    <div style={{ textAlign: "center" }}>
      <div id="countdown" className="countdownHolder text-white">
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const Myaccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [raffle, setRaffle] = useState({});
  const [my_account, setMy_account] = useState();
  const [createdRaffle, setcreatedRaffle] = useState([]);
  const [purchasedRaffle, setpurchasedRaffle] = useState([]);
  const [purchasedTicketsCount, setpurchasedTicketsCount] = useState<any | null>(null);

  const [participants, setParticipants] = useState();
  const [showPurchasedTable, setShowPurchasedTable] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const navigate = useNavigate();

  const [isPollTimeEnd, setisPollTimeEnd] = useState(false);


  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  // const get_raffle_details = async (raffle_id) => {
  //   let r = await get_raffle(raffle_id);
  //   let t = await RaffleData(connection, raffle_id);
  //   console.log(t);
  //   t["id"] = raffle_id;
  //   t["raffleName"] = r["raffleName"];
  //   t["raffleType"] = r["raffleType"];
  //   t["imageData"] = r["imageData"];
  //   const time = new Date(t.end_timestamp * 1000);

  //   t["timer"] = time.getTime();
  //   let now = moment.utc();
  //   var day = moment.unix(t.end_timestamp);
  //   var daysDiff = day.diff(now, "days");
  //   var hoursDiff = day.diff(now, "hours");
  //   var minutesDiff = day.diff(now, "minutes");
  //   var secondsDiff = day.diff(now, "seconds");
  //   console.log(daysDiff, hoursDiff, minutesDiff, secondsDiff);
  //   if (daysDiff > 0) {
  //     setisPollTimeEnd(false);
  //   } else {
  //     if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
  //       setisPollTimeEnd(false);
  //     } else {
  //       console.log("time end");
  //       setisPollTimeEnd(true);
  //     }
  //   }

  //   setRaffle(t);

  //   let participant = t["entries"].reduce(function (obj, b) {
  //     obj[b] = ++obj[b] || 1;
  //     console.log(obj);
  //     return obj;
  //   }, {});
  //   setParticipants(participant);

  //   console.log(participant);

  //   localStorage.setItem("raffleword", raffle_id);
  //   localStorage.setItem("ticketToken", t["ticket_mint"]);
  //   localStorage.setItem("ticketPrice", t["ticket_price"]);
  // };

  const get_my_details = async () => {
    if (!publicKey) return;
    try {
      setIsLoading(true)
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getMyProfile/" + publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();
      setMy_account(data);
      let a = [];
      let t_c = 0
      if (data['purchasedTickets'] !== null && data["purchasedTickets"].length > 0) {
        for (const raffle of data["purchasedTickets"]) {
          let t = await RaffleData(connection, raffle["id"]);
          t["id"] = raffle["id"];
          t["raffleName"] = raffle["raffleName"];

          t_c = t["entries"].filter(x => x === publicKey.toString()).length + t_c

          const time = new Date(t.end_timestamp * 1000);

          t["timer"] = time.getTime();

          let now = moment.utc();
          var day = moment.unix(t.end_timestamp);
          var daysDiff = day.diff(now, "days");
          var hoursDiff = day.diff(now, "hours");
          var minutesDiff = day.diff(now, "minutes");
          var secondsDiff = day.diff(now, "seconds");

          if (daysDiff > 0) {
            t["raffle_ended"] = false;
          } else {
            if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
              t["raffle_ended"] = false;
            } else {
              t["raffle_ended"] = true;
            }
          }


          if (raffle["rafflePriceType"] === "nft") {
            let tokenmetaPubkey = await Metadata.getPDA(
              new PublicKey(t["prize_key"])
            );
            // console.log(tokenmetaPubkey.toString());
            const tokenmeta = await Metadata.load(connection, tokenmetaPubkey);
            // console.log(tokenmeta);
            let img_response = await fetch(tokenmeta["data"]["data"]["uri"]);
            let img = await img_response.json();
            // console.log(img);
            t["nft_image"] = img["image"];
            t["raffleName"] = img["name"];
          }

          a.push(t);
        }
      }
      setpurchasedTicketsCount(t_c)
      setpurchasedRaffle(a);
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e);
    }
  };

  useEffect(() => {
    get_my_details();

    // get_dao_by_id();
  }, [publicKey]);

  const tab_toggler = async () => {
    setShowPurchasedTable(!showPurchasedTable);
    console.log(showPurchasedTable);
  };

  let call_raffle_details = (param) => (e) => {
    navigate("/raffle/" + param.id);
  };

  return (
    <>
    <div className="desk-top-view">
    {isLoading && <PreLoader />}
    {!isLoading && (
    <>
      <div className="my-account container-custom container-xxl d-lg-flex flex-column w-100">
        <div className="card mb-5 mt-5 mb-xl-10">
          <div className="card-body pt-9 pb-0">
            <div className={`${isMobile ? "flex-wrap flex-sm-nowrap mb-3" : "d-flex flex-wrap flex-sm-nowrap mb-3"}`}>
              <div className="flex-grow-1">
                <div className={`${isMobile ? "justify-content-between align-items-start flex-wrap mb-2" : "d-flex justify-content-between align-items-start flex-wrap mb-2"}`}>
                  <div className={`${isMobile ? "flex-column" : "d-flex flex-column"}`}>
                    <div className={`${isMobile ? "align-items-center mb-1" : "d-flex align-items-center mb-1"}`}>
                      <a href="#" className="text-white fs-1 fw-bolder me-3">
                        My Account
                      </a>
                    </div>
                    <div  className={`${isMobile ? " align-items-center mb-2" : "d-flex align-items-center mb-2"}`} >
                      <span className="text-yellow text-hover-primary fs-5 fw-bold me-1">
                        {publicKey ? publicKey.toString() : ""}
                      </span>
                    </div>
                  </div>

                  {/* <div className="d-flex my-4">
                    <a href="#" className="btn btn-sm btn-primary me-2">
                      Link Twiiter
                    </a>
                  </div> */}
                </div>
                <div className="d-flex flex-wrap flex-stack">
                  <div className="d-flex flex-column flex-grow-1 pe-8">
                    <div className="d-flex flex-wrap">
                      <div>
                        {/* <img className="my-account-img" src={my_account_img} /> */}
                      </div>

                      {/* <div className="my-account-small-box min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="label">No of Raffle Created</div>
                        <div className="d-flex align-items-center">
                          <div className="text">{createdRaffle !== undefined ? createdRaffle.length : 0}</div>
                        </div>
                      </div> */}
                      <div className="my-account-small-box-2 min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="label">No of Purchased ticket</div>
                        <div className="d-flex align-items-center">
                          <div className="text">{purchasedRaffle !== undefined ? purchasedTicketsCount  : 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="slider-container-myaccount">
          <label className="switch-myaccount">
            <input
              type="checkbox"
              onChange={() => tab_toggler()}
              id="toggle_vat"
              className="toggle_vat"
            />
            <div className="slider round">
              <span className="slider_text">
                <span className="off">Purchased</span>
                <span className="on">Created</span>
              </span>
            </div>
          </label>
        </div> */}
        <div className="row gy-5 g-xl-10">
          <h3 className="card-title align-items-start flex-column">
            <span className="fw-bold text-white recent-raffle-text">
              <img className="flame-img" src={flame_img} /> Purchased Raffle
              <img className="flame-img" src={flame_img} />
            </span>
          </h3>
          <div className="col-xl-12">
            <div className="my-account-table-card card card-flush h-xl-100">
              <div className="card-body">
                <div
                  id="kt_table_widget_5_table_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div className="table-responsive">
                    <table
                      className="table align-middle fs-6 gy-3 dataTable no-footer"
                      id="kt_table_widget_5_table"
                    >
                      <thead>
                        <tr className="text-start text-blue fw-bold fs-7 text-uppercase gs-0">
                          <th
                            className="pe-3 min-w-100px sorting_disabled text-center"
                            aria-label="Product ID"
                          >
                            Raffle
                          </th>
                          <th
                            className="pe-3 min-w-150px sorting text-center"
                            aria-controls="kt_table_widget_5_table"
                            aria-label="Date Added: activate to sort column ascending"
                          >
                            Time Remaining
                          </th>
                          <th
                            className=" pe-3 min-w-100px text-center sorting"
                            aria-controls="kt_table_widget_5_table"
                            aria-label="Price: activate to sort column ascending"
                          >
                            Tickets Remaining
                          </th>

                          <th
                            className=" pe-0 min-w-25px sorting text-center"
                            aria-controls="kt_table_widget_5_table"
                            aria-label="Qty: activate to sort column ascending"
                          >
                            Ticket Bought
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      {purchasedRaffle !== undefined && (
                        <tbody className="fw-bold text-gray-600">
                          {purchasedRaffle.map((raffle, index) => (
                            <tr className="odd">
                              <td className="text-white text-center">
                                {raffle["raffleName"]}
                              </td>

                              <td
                                className="text-white text-center"
                                data-order="2022-04-20T00:00:00+05:30"
                              >
                                {raffle["raffle_ended"] ? (
                                  "Ended"
                                ) : (
                                  <MyTimer expiryTimestamp={raffle["timer"]} />
                                )}
                              </td>

                              <td className="text-white text-center">
                                {raffle["total_entries"]}/
                                {raffle["max_entries"]}{" "}
                                {/* <div className="text-blue fs-7">0.1 DUST</div> */}
                              </td>

                              <td className="text-white text-center">
                                { raffle["entries"].filter(x => x === publicKey.toString()).length }
                              </td>
                              <td className="text-end text-center">
                                <button
                                  onClick={call_raffle_details(raffle)}
                                  className="btn btn-view-myaccount"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    )}
     </div>
      <div className="mobile-view text-white fs-2">
        <img src={logo_white} />
        <div>Please use this app in desktop for better experience</div>
      </div>
    </>
  );
};
