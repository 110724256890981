import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { RaffleData } from "../util/raffle";
import nft_img_1 from "../images/956.png";
import heading_img_1 from "../images/Heading.png";
import logo_white from "../images/logo-white.png";
import flame_img from "../images/Flame.png";
import collection_img from "../images/Group 193.png";
import no_raffle_img from "../images/empty-box.png";
import "./home.css";
import moment from "moment";
import { useTimer } from "react-timer-hook";
import { PreLoader } from "../components/pre-loader";

import { PublicKey } from "@solana/web3.js";

import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.log("end"),
  });

  return (
    <div
      style={{ textAlign: "center" }}
      className="raffle-time badge  fs-8 fw-bold"
    >
      <div id="countdown" className="countdownHolder">
        Raffle Ends on :{" "}
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const Home = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [limit, setLimit] = useState(10);
  const [allRaffle, setallRaffle] = useState([]);
  const [pastRaffle, setpastRaffle] = useState([]);
  const [recentRaffle, setrecentRaffle] = useState([]);
  const [search, setsearch] = useState("");
  const [foundRaffle, setFoundRaffle] = useState([]);
  const navigate = useNavigate();
  const getAllRaffle = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getAllRaffle",
        { mode: "cors" }
      );
      let data = await response.json();
      // data = data.reverse();
      // if (data.length > 5) {
      //   setIsLoadingPage(true);
      // }
      let a = [];
      let b = [];
      let c = [];
      for (const raffle of data.reverse()) {
        if (
          raffle["transactionHash"] !== null &&
          raffle["transactionHash"] !== ""
        ) {
          let t = await RaffleData(connection, raffle["id"]);
          if (t !== undefined) {
            t["id"] = raffle["id"];
            t["raffleName"] = raffle["raffleName"];
            t["collectionName"] = raffle["collectionName"];
            t["raffleType"] = raffle["raffleType"];
            t["rafflePriceType"] = raffle["rafflePriceType"];
            t["imageData"] = raffle["imageData"];

            const time = new Date(t.end_timestamp * 1000);

            t["timer"] = time.getTime();

            let now = moment.utc();
            var day = moment.unix(t.end_timestamp);
            var daysDiff = day.diff(now, "days");
            var hoursDiff = day.diff(now, "hours");
            var minutesDiff = day.diff(now, "minutes");
            var secondsDiff = day.diff(now, "seconds");

            if (daysDiff > 0) {
              t["raffle_ended"] = false;
            } else {
              if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
                t["raffle_ended"] = false;
              } else {
                t["raffle_ended"] = true;
              }
            }

            if (raffle["rafflePriceType"] === "nft") {
              let tokenmetaPubkey = await Metadata.getPDA(
                new PublicKey(t["prize_key"])
              );

              const tokenmeta = await Metadata.load(
                connection,
                tokenmetaPubkey
              );

              let img_response = await fetch(tokenmeta["data"]["data"]["uri"]);
              let img = await img_response.json();

              t["nft_image"] = img["image"];
              t["raffleName"] = img["name"];
            }
            if (t["raffle_ended"] === true) {
              b.push(t);
            } else {
              a.push(t);
            }

            c.push(t);
          }
        }
      }

      setallRaffle(c);
      setrecentRaffle(a);
      await setpastRaffle(b);
      setIsLoading(false);

      // await setFoundUsers(data);
      // await setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getRaffleData = async () => {};

  let call_raffle_details = (param) => (e) => {
    navigate("/raffle/" + param.id);
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = allRaffle.filter((search) => {
        if (search.raffleName !== null) {
          return search.raffleName.toLowerCase().match(keyword.toLowerCase());
        } else {
          return "";
        }

        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundRaffle(results);
    } else {
      setFoundRaffle([]);

      // If the text field is empty, show all users
    }
    setsearch(keyword);
  };

  useEffect(() => {
    async function data() {
      // await setIsLoading(true);
      await getAllRaffle();
      // console.log("d");
      // await get_floor_price("the_vaultx_dao");
    }
    data();
  }, [navigate]);

  const notify = () => console.log("refresh");

  const refreshData = async () => {
    await getAllRaffle();
  };

  const get_floor_price = async (c_name) => {
    try {
      const response = await fetch(
        "https://api-mainnet.magiceden.dev/v2/collections/" +
          c_name +
          "/stats/",

        {
          headers: new Headers({
            "User-Agent": "Mozilla/4.0",
          }),
          mode: "cors",
        }
      );
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
    }
  };





  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     refreshData();
  //   }, 100000);
  //   return () => {
  //     // clean up
  //     clearInterval(intervalCall);
  //   };
  // }, []);

  return (
    <>
      <div className="desk-top-view">
        {isLoading && <PreLoader />}
        {!isLoading && (
          <div
            id="kt_content_container"
            className="d-flex flex-column flex-column-fluid"
          >
            <section id="hero_section">
              <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
                <div
                  id="kt_toolbar_container"
                  className="container-xxl text-center"
                >
                  <div className="align-items-center">
                    <h1 className="heading-1 mt-3 text-white">
                      Discover <img src={heading_img_1} /> Collect and Sell
                      Super Raffles.
                    </h1>
                    <h2 className="heading-2 mt-5 text-white">
                      FInd and explore best raffles around you...
                    </h2>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="d-lg-flex align-lg-items-center">
                      <div className="search-container-box  rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-lg-12 d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="17.0365"
                                  y="15.1223"
                                  width="8.15546"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 17.0365 15.1223)"
                                  fill="currentColor"
                                ></rect>
                                <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>

                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="search"
                              value={search}
                              onChange={filter}
                              placeholder="Your Search"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {foundRaffle.length > 0 && (
                      <ul className="drop">
                        {foundRaffle.map((raffle, index) => (
                          <li onClick={call_raffle_details(raffle)}>
                            <div>
                              <span>
                                {raffle["rafflePriceType"] === "nft" ? (
                                  <>
                                    {raffle["nft_image"] !== null ? (
                                      <img
                                        className="search-filer-img"
                                        alt="raffle_image"
                                        src={raffle["nft_image"]}
                                      />
                                    ) : (
                                      <img
                                        className="search-filer-img"
                                        alt="raffle_image"
                                        src={nft_img_1}
                                      />
                                    )}
                                  </>
                                ) : raffle["imageData"] !== null ? (
                                  <img
                                    className="search-filer-img"
                                    alt="raffle_imagedd"
                                    src={
                                      "data:image/png;base64," +
                                      raffle["imageData"]
                                    }
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </span>
                              <span className="text-white search-result-text">
                                {raffle.raffleName}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="d-flex align-items-center py-3 py-md-1"></div>
                </div>
              </div>
            </section>
            <div className="container-xxl" id="kt_content">
              <div className="row gy-5 g-xl-10 mt-5">
                <div className="col-xl-8">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="fw-bold text-white recent-raffle-text">
                      <img className="flame-img" src={flame_img} /> Raffles - Live
                      <img className="flame-img" src={flame_img} />
                    </span>
                  </h3>
                </div>

                <div className="col-xl-4">
                  <div className="card-toolbar mb-10">
                    {/* <div className="d-flex align-items-center float-right">
                    <span className="fs-7 fw-bold text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
                      Sort By:
                    </span>

                    <select
                      className="form-select form-select-sm form-select-solid sort-by-select"
                      aria-hidden="true"
                    >
                      <option
                        value=""
                        data-select2-id="select2-data-128-3ifn"
                      ></option>
                      <option value="1" data-select2-id="select2-data-9-0hl4">
                        All
                      </option>
                      <option value="2" data-select2-id="select2-data-129-sfxc">
                        Individual
                      </option>
                      <option value="3" data-select2-id="select2-data-130-pd72">
                        DAO
                      </option>
                    </select>
                  </div> */}
                  </div>
                </div>
              </div>
              {recentRaffle.length > 0 && (
                <div className="row gy-5 g-xl-10 mt-5">
                  {recentRaffle.map((raffle, index) => (
                    <div className="col-xl-3">
                      <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card">
                        <div className="card-body d-flex  flex-column">
                          <div className="raffle-img-on-card text-center">
                            {raffle["rafflePriceType"] === "nft" ? (
                              <>
                                {raffle["nft_image"] !== null ? (
                                  <img
                                    alt="raffle_image"
                                    src={raffle["nft_image"]}
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </>
                            ) : raffle["imageData"] !== null ? (
                              <img
                                alt="raffle_imagedd"
                                src={
                                  "data:image/png;base64," + raffle["imageData"]
                                }
                              />
                            ) : (
                              <img alt="raffle_image" src={nft_img_1} />
                            )}
                          </div>
                          {!raffle["raffle_ended"] && (
                            <MyTimer expiryTimestamp={raffle["timer"]} />
                          )}
                          {raffle["raffle_ended"] && (
                            <div className="raffle-time fs-8 fw-bold">
                              Raffle Ended On :{" "}
                              {moment
                                .unix(raffle["end_timestamp"])
                                .format("MMM Do, YY")}
                            </div>
                          )}
                          {raffle["rafflePriceType"] === "nft" ? (
                            <div className="collection-name text-left pl-0">
                              {raffle["collectionName"]}{" "}
                              <img
                                className="collection-img"
                                src={collection_img}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="raffle-name text-white text-hover-primary fw-bold mb-0">
                            {raffle["raffleName"]}
                          </div>

                          {/* <div className="fw-semibold text-gray-400 mb-6 overflow">
                        {raffle["creator"]}
                      </div> */}

                          <div className="d-flex flex-center flex-wrap mb-1 ticket-container">
                            <div className="py-3 px-4 mx-2 mb-3">
                              <div className="fs-6 fw-bold text-white">
                                <span className="ticket-sold">
                                  {" "}
                                  {raffle["total_entries"]}{" "}
                                </span>
                                /
                                <span className="ticket-remain">
                                  {raffle["max_entries"]}
                                </span>
                              </div>
                              <div className="fw-semibold ticket-sold-label">
                                Tickets Sold
                              </div>
                            </div>
                            <div className="verticle-line"></div>
                            <div className=" min-w-90px py-3 px-4 mx-2 mb-3">
                              <div className="fs-6 fw-bold text-white">
                                <span className="ticket-price">
                                  {" "}
                                  {raffle["ticket_price"] / 1} VAULT{" "}
                                </span>
                              </div>
                              <div className="fw-semibold ticket-price-label">
                                Prize / Ticket
                              </div>
                            </div>
                          </div>

                          <button
                            className="btn view-raffle-btn"
                            onClick={call_raffle_details(raffle)}
                          >
                            <span className="view-raffle-text">
                              View Raffle
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {recentRaffle.length === 0 && (
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-12 text-center">
                    <img width="170" src={no_raffle_img} alt="no_raffle" />
                    <div className="fs-5 text-muted">No Live Raffles</div>
                  </div>
                </div>
              )}
              <div className="row gy-5 g-xl-10 mt-5">
                <div className="col-xl-8">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="fw-bold text-white recent-raffle-text">
                      <img className="flame-img" src={flame_img} /> Raffles - Ended
                      <img className="flame-img" src={flame_img} />
                    </span>
                  </h3>
                </div>
                <div className="col-xl-4">
                  <div className="card-toolbar mb-10">
                    {/* <div className="d-flex align-items-center float-right">
                    <span className="fs-7 fw-bold text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
                      Sort By:
                    </span>

                    <select
                      className="form-select form-select-sm form-select-solid sort-by-select"
                      aria-hidden="true"
                    >
                      <option
                        value=""
                        data-select2-id="select2-data-128-3ifn"
                      ></option>
                      <option value="1" data-select2-id="select2-data-9-0hl4">
                        All
                      </option>
                      <option value="2" data-select2-id="select2-data-129-sfxc">
                        Individual
                      </option>
                      <option value="3" data-select2-id="select2-data-130-pd72">
                        DAO
                      </option>
                    </select>
                  </div> */}
                  </div>
                </div>
              </div>
              {pastRaffle.length !== 0 && (
                <div className="row gy-5 g-xl-10 mt-5">
                  {pastRaffle.map((raffle, index) => (
                    <div className="col-xl-3">
                      <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card">
                        <div className="card-body d-flex  flex-column">
                          <div className="raffle-img-on-card text-center">
                            {raffle["rafflePriceType"] === "nft" ? (
                              <>
                                {raffle["nft_image"] !== null ? (
                                  <img
                                    alt="raffle_image"
                                    src={raffle["nft_image"]}
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </>
                            ) : raffle["imageData"] !== null ? (
                              <img
                                alt="raffle_imagedd"
                                src={
                                  "data:image/png;base64," + raffle["imageData"]
                                }
                              />
                            ) : (
                              <img alt="raffle_image" src={nft_img_1} />
                            )}
                          </div>
                          {!raffle["raffle_ended"] && (
                            <MyTimer expiryTimestamp={raffle["timer"]} />
                          )}
                          {raffle["raffle_ended"] && (
                            <div className="raffle-time fs-8 fw-bold">
                              Raffle Ended On :{" "}
                              {moment
                                .unix(raffle["end_timestamp"])
                                .format("MMM Do, YY")}
                            </div>
                          )}
                          {raffle["rafflePriceType"] === "nft" ? (
                            <div className="collection-name text-left pl-0">
                              {raffle["collectionName"]}{" "}
                              <img
                                className="collection-img"
                                src={collection_img}
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="raffle-name text-white text-hover-primary fw-bold mb-0">
                            {raffle["raffleName"]}
                          </div>

                          {/* <div className="fw-semibold text-gray-400 mb-6 overflow">
                        {raffle["creator"]}
                      </div> */}

                          <div className="d-flex flex-center flex-wrap mb-1 ticket-container">
                            <div className="py-3 px-4 mx-2 mb-3">
                              <div className="fs-6 fw-bold text-white">
                                <span className="ticket-sold">
                                  {" "}
                                  {raffle["total_entries"]}{" "}
                                </span>
                                /
                                <span className="ticket-remain">
                                  {raffle["max_entries"]}
                                </span>
                              </div>
                              <div className="fw-semibold ticket-sold-label">
                                Tickets Sold
                              </div>
                            </div>
                            <div className="verticle-line"></div>
                            <div className=" min-w-90px py-3 px-4 mx-2 mb-3">
                              <div className="fs-6 fw-bold text-white">
                                <span className="ticket-price">
                                  {" "}
                                  {raffle["ticket_price"] / 1} VAULT{" "}
                                </span>
                              </div>
                              <div className="fw-semibold ticket-price-label">
                                Prize / Ticket
                              </div>
                            </div>
                          </div>

                          <button
                            className="btn view-raffle-btn"
                            onClick={call_raffle_details(raffle)}
                          >
                            <span className="view-raffle-text">
                              View Raffle
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {pastRaffle.length === 0 && (
                <div className="row gy-5 g-xl-10 mb-5">
                  <div className="col-xl-12 text-center">
                    <img width="170" src={no_raffle_img} alt="no_raffle" />
                    <div className="fs-5 text-muted">No Ended Raffles</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mobile-view text-white fs-2">
        <img src={logo_white} />
        <div>Please use this app in desktop for better experience</div>
      </div>
    </>
  );
};
